// Scheduler data to be shared between dashboard components
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { isEmpty, map, startCase } from 'lodash';

import { useAuthContext } from '@/lib/context/AuthContext';
import { URLParams, useURLParamsContext } from '@/lib/context/URLParamsContext';

import { firestore } from '@/lib/firebase';
import {
  jobDispatchStageValues,
  jobOverallStatusValues,
} from '@/lib/firebase/db/@types';
import {
  getJobBrokerCompanyName,
  getJobDispatchStage,
  getJobProductName,
  getJobSitesNames,
  loadBrokerCompaniesFromJobs,
  sortJobsByStatus,
} from '@/lib/firebase/db/helpers';
import {
  CompanyDoc,
  DispatchScheduleDoc,
  JobDoc,
} from '@/lib/firebase/db/metaTypes';
import {
  JOBS_BY_DATE_DEFAULT_SORT_FIELD,
  JOBS_BY_DATE_DEFAULT_SORT_ORDER,
  dispatchScheduleQuery,
} from '@/lib/firebase/db/queries';

import { addDays, getEndOfWeek, getStartOfWeek } from '@/lib/helpers/dates';
import {
  filterValuesToString,
  getFilterOptionsFromJobs,
  getUpdatedFilterValues,
  stringToFilterValues,
} from '@/lib/helpers/scheduler';

export const sortOrders = ['asc', 'desc'] as const;
export type SortOrder = (typeof sortOrders)[number];

export const sortFields = [
  'startDatetime',
  'clientName',
  'overallStatus',
] as const;
export type SortField = (typeof sortFields)[number];

type SortFunction = (jobs: JobDoc[], sortOder: SortOrder) => JobDoc[];
// Allow specifying a custom sorting function for any particular SortField
export const jobsSorters: Partial<Record<SortField, SortFunction>> = {
  overallStatus: sortJobsByStatus,
};

export const filterFields = [
  'stage',
  'overallStatus',
  'clientName',
  'brokerCompanyRef',
  'site',
  'material',
  'unit',
] as const;
export type FilterField = (typeof filterFields)[number];

/**
 * Defines a type for the record that holds filter options.
 * This type is a partial record, meaning each key is optional.
 * The keys are of type `FilterField`, and the values are arrays of strings.
 */
export type FilterOptionsRecord = Partial<Record<FilterField, string[]>>;

export type BrokerCompaniesRecord = Record<string, CompanyDoc>;

/**
 * Defines the configuration for filters used in a scheduler.
 * This configuration may include an accessor function to retrieve the filter value from a job,
 * and a label for the filter field.
 * @property {Function} [accessor] - An optional function to access the filter value from a job object.
 * @property {string} [label] - An optional label for the filter field.
 * @property {string[]} [options] - optionally specify the options to be shown instead of generating them from jobs.
 * @property {Function} [formatOption] - An optional function to format the option value string to be shown in filter list.
 */
export type SchedulerFilterConfig = {
  accessor?: (
    job: JobDoc,
    { brokerCompanies }: { brokerCompanies?: BrokerCompaniesRecord }
  ) => string | string[];
  label?: string;
  options?: string[];
  formatOption?: (value: string) => string;
};
export const schedulerFilterColumns: Record<
  FilterField,
  SchedulerFilterConfig
> = {
  stage: {
    accessor: getJobDispatchStage,
    options: [...jobDispatchStageValues],
    formatOption: startCase,
  },
  overallStatus: {
    label: 'Status',
    options: [...jobOverallStatusValues],
    formatOption: (value) => startCase(value.toLowerCase()),
  },
  clientName: {
    label: 'Client',
  },
  brokerCompanyRef: {
    label: 'Broker',
    accessor: getJobBrokerCompanyName,
  },
  site: {
    accessor: getJobSitesNames,
  },
  material: {
    accessor: getJobProductName,
  },
  unit: {
    formatOption: startCase,
  },
};

export type DispatchScheduleOption = {
  name: string;
  dispatchScheduleId: string | null;
  dispatchScheduleDoc: DispatchScheduleDoc | null;
};

const DEFAULT_DISPATCH_SCHEDULE_NAME = 'All Jobs';
const DEFAULT_DISPATCH_SCHEDULE_OPTION: DispatchScheduleOption = {
  name: DEFAULT_DISPATCH_SCHEDULE_NAME,
  dispatchScheduleId: null,
  dispatchScheduleDoc: null,
};

// Options array is never empty as it contains at least DEFAULT_DISPATCH_SCHEDULE_OPTION
type DispatchScheduleOptionsArray = [
  DispatchScheduleOption,
  ...DispatchScheduleOption[],
];

interface SchedulerContextType {
  // Properties to manage Current Dispatch Schedule
  selectedDispatchSchedule: DispatchScheduleOption;
  dispatchSchedulesOptions: DispatchScheduleOptionsArray;
  isLoadingDispatchScheduleOptions: boolean;
  setSelectedDispatchSchedule: (
    dispatchScheduleOption: DispatchScheduleOption
  ) => void;
  // Properties to Manage Scheduler Date range
  schedulerStartDate: Date;
  schedulerEndDate: Date;
  setSchedulerRangeDates: (startDate: Date, endDate: Date) => void;
  schedulerCurrentDate: Date;
  setSchedulerCurrentDate: (currentDate: Date) => void;
  // Properties to Manage Scheduler Sort Order
  schedulerSortField: SortField;
  schedulerSortOrder: SortOrder;
  setSchedulerSortField: (sortField: SortField) => void;
  setSchedulerSortOrder: (sortOrder: SortOrder) => void;
  // Properties to Manage Scheduler Job Filtering
  schedulerFilterOptions: FilterOptionsRecord;
  initializeSchedulerFilterOptions: (jobs: JobDoc[]) => void;
  schedulerFilterValues: FilterOptionsRecord;
  activeFilters: FilterOptionsRecord;
  activeFiltersCount: number;
  updateSchedulerFilterValue: (
    filterField: FilterField,
    filterValue: string
  ) => void;
  clearActiveFilters: () => void;
  showOperators: boolean;
  onToggleShowOperators: () => void;
  brokerCompanies: BrokerCompaniesRecord;
  isLoadingBrokerCompanies: boolean;
}

export const SchedulerContext = React.createContext<SchedulerContextType>({
  selectedDispatchSchedule: DEFAULT_DISPATCH_SCHEDULE_OPTION,
  dispatchSchedulesOptions: [DEFAULT_DISPATCH_SCHEDULE_OPTION],
  isLoadingDispatchScheduleOptions: false,
  setSelectedDispatchSchedule: () => null,
  schedulerStartDate: new Date(),
  schedulerEndDate: new Date(),
  setSchedulerRangeDates: () => null,
  schedulerCurrentDate: new Date(),
  setSchedulerCurrentDate: () => null,
  schedulerSortField: JOBS_BY_DATE_DEFAULT_SORT_FIELD,
  schedulerSortOrder: JOBS_BY_DATE_DEFAULT_SORT_ORDER,
  setSchedulerSortField: () => null,
  setSchedulerSortOrder: () => null,
  schedulerFilterOptions: {},
  initializeSchedulerFilterOptions: () => null,
  schedulerFilterValues: {},
  activeFilters: {},
  activeFiltersCount: 0,
  updateSchedulerFilterValue: () => null,
  clearActiveFilters: () => null,
  showOperators: true,
  onToggleShowOperators: () => null,
  brokerCompanies: {},
  isLoadingBrokerCompanies: false,
});

export const useSchedulerContext = () => useContext(SchedulerContext);

export const SchedulerContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { userDoc } = useAuthContext();
  const {
    updateParam,
    date: dateParam,
    schedule: scheduleParam,
    sortBy,
    order,
    operators: showOperatorsParam,
    filters: filtersParam,
  } = useURLParamsContext();

  const [selectedDispatchSchedule, setSelectedDispatchSchedule_] = useState(
    DEFAULT_DISPATCH_SCHEDULE_OPTION
  );
  function setSelectedDispatchSchedule(
    dispatchScheduleOption: DispatchScheduleOption
  ) {
    setSelectedDispatchSchedule_(dispatchScheduleOption);
    updateParam('schedule', dispatchScheduleOption.dispatchScheduleId || '');
  }

  const [dispatchSchedulesOptions, setDispatchSchedulesOptions] =
    useState<DispatchScheduleOptionsArray>([DEFAULT_DISPATCH_SCHEDULE_OPTION]);
  const [
    isLoadingDispatchScheduleOptions,
    setIsLoadingDispatchScheduleOptions,
  ] = useState(true);

  const [brokerCompanies, setBrokerCompanies] = useState<BrokerCompaniesRecord>(
    {}
  );
  const [isLoadingBrokerCompanies, setIsLoadingBrokerCompanies] =
    useState(true);

  // Load Dispatch Schedule Options at start up
  useEffect(() => {
    if (!userDoc) return;
    const unSubscribe = firestore.onSnapshot(
      dispatchScheduleQuery({ userDoc }),
      (snapshot) => {
        const dispatchSchedulesDocs = firestore.filterSoftDeletedDocs(
          snapshot.docs
        );
        const options: DispatchScheduleOption[] = dispatchSchedulesDocs.map(
          (dispatchScheduleDoc) => {
            const dispatchScheduleData = dispatchScheduleDoc.data();
            return {
              dispatchScheduleId: dispatchScheduleDoc.id,
              name: dispatchScheduleData.name,
              dispatchScheduleDoc,
            };
          }
        );
        setDispatchSchedulesOptions([
          DEFAULT_DISPATCH_SCHEDULE_OPTION,
          ...options,
        ]);

        // Check if schedule url param is set of if user has a default DispatchSchedule preference set
        const defaultDispatchScheduleId =
          scheduleParam ||
          userDoc.get('preferences.dispatchSchedule.defaultDispatchScheduleId');
        if (!!defaultDispatchScheduleId) {
          const defaultDispatchScheduleOption =
            options.find(
              (option) =>
                option.dispatchScheduleId === defaultDispatchScheduleId
            ) || DEFAULT_DISPATCH_SCHEDULE_OPTION;
          setSelectedDispatchSchedule(defaultDispatchScheduleOption);
        }
        setIsLoadingDispatchScheduleOptions(false);
      }
    );
    return () => unSubscribe();
  }, [!!userDoc]);

  const currentDate = !!dateParam ? new Date(`${dateParam} 12:00`) : new Date();
  const [schedulerStartDate, setSchedulerStartDate] = useState<Date>(
    getStartOfWeek(currentDate)
  );
  const [schedulerEndDate, setSchedulerEndDate] = useState<Date>(
    getEndOfWeek(currentDate)
  );
  const [schedulerCurrentDate, setSchedulerCurrentDate_] =
    useState<Date>(currentDate);
  function setSchedulerCurrentDate(date: Date) {
    setSchedulerCurrentDate_(date);
    // Extract date string as ISO date format
    let dateStr = date.getDateISOStr();
    const isToday = dateStr === new Date().getDateISOStr();
    // clear date param when selected date is current day
    updateParam('date', !isToday ? dateStr : '');
  }

  function setSchedulerRangeDates(startDate: Date, endDate: Date) {
    setSchedulerStartDate(startDate);
    setSchedulerEndDate(endDate);
    // Set scheduler date to same weekday as the one currently selected
    setSchedulerCurrentDate(addDays(startDate, schedulerCurrentDate.getDay()));
  }

  const [schedulerSortField, setSchedulerSortField] = useState<SortField>(
    (sortBy as SortField) || JOBS_BY_DATE_DEFAULT_SORT_FIELD
  );
  const [schedulerSortOrder, setSchedulerSortOrder] = useState<SortOrder>(
    (order as SortOrder) || JOBS_BY_DATE_DEFAULT_SORT_ORDER
  );

  const [schedulerFilterOptions, setSchedulerFilterOptions] =
    useState<FilterOptionsRecord>({});
  // schedulerFilterValues holds the selected values.
  const [schedulerFilterValues, setSchedulerFilterValues] =
    useState<FilterOptionsRecord>(stringToFilterValues(filtersParam));

  /**
   * Initializes the filter options for a job scheduler based on a provided list of jobs.
   * This function is designed to set up the necessary filter options for a job scheduling interface,
   * leveraging the job data to populate these options. It first extracts filter options directly from
   * the job documents themselves. After setting these initial filter options, it proceeds to asynchronously
   * load detailed information about the broker companies associated with the provided jobs.
   *
   * Once the broker company data is fetched, it updates the state to include this data for use in the scheduler,
   * also marking the completion of the loading process. This approach ensures that the scheduler has access to
   * comprehensive filter options, including dynamically loaded data about broker companies, enhancing the
   * user's ability to filter and schedule jobs effectively.
   *
   * @param {JobDoc[]} jobs - An array of job documents from which to derive initial filter options and to fetch
   *                          associated broker company information.
   */
  function initializeSchedulerFilterOptions(jobs: JobDoc[]) {
    setIsLoadingBrokerCompanies(true);
    loadBrokerCompaniesFromJobs(jobs).then((brokerCompanies) => {
      const filterOptions = getFilterOptionsFromJobs(jobs, { brokerCompanies });
      setSchedulerFilterOptions(filterOptions);
      setBrokerCompanies(brokerCompanies);
      setIsLoadingBrokerCompanies(false);
    });
  }

  function updateSchedulerFilterValue(
    filterField: FilterField,
    filterValue: string
  ) {
    const currentFilterValues = schedulerFilterValues[filterField] || [];
    const newFilterValues = getUpdatedFilterValues(
      currentFilterValues,
      filterValue
    );
    setSchedulerFilterValues({
      ...schedulerFilterValues,
      [filterField]: newFilterValues,
    });
  }

  function clearActiveFilters() {
    const newFilterValues: FilterOptionsRecord = {};
    filterFields.forEach((filterField) => {
      newFilterValues[filterField] = activeFilters[filterField]?.reduce(
        (updatedFilters = [], filterValue) =>
          getUpdatedFilterValues(updatedFilters, filterValue),
        schedulerFilterValues[filterField]
      );
    });
    setSchedulerFilterValues(newFilterValues);
  }

  // update filter URL param when selected filters are updated
  useEffect(() => {
    if (isEmpty(schedulerFilterValues) && !filtersParam) {
      return;
    }
    const filtersString = filterValuesToString(schedulerFilterValues);
    updateParam('filters', filtersString);
  }, [schedulerFilterValues]);

  // Hold the active filters based on the available options.
  // E.g. switching from one day to another may change available options
  // meaning some of the selected filters are not applicable in current day.
  const activeFilters = useMemo(() => {
    const activeFilters: FilterOptionsRecord = {};
    filterFields.forEach((filterField) => {
      activeFilters[filterField] = schedulerFilterValues[filterField]?.filter(
        (filterValue) =>
          schedulerFilterOptions[filterField]?.includes(filterValue)
      );
    });
    return activeFilters;
  }, [schedulerFilterOptions, schedulerFilterValues]);

  // Keep track of active filters count for components
  // to know if there's some filter active
  const activeFiltersCount = useMemo(() => {
    return map(activeFilters)
      .flat()
      .filter((f) => !!f).length;
  }, [activeFilters]);

  const [showOperators, setShowOperators] = useState(
    showOperatorsParam !== 'false'
  );

  function onToggleShowOperators() {
    const newValueShowOperators = !showOperators;
    setShowOperators(newValueShowOperators);
    updateParam('operators', `${newValueShowOperators}`);
  }

  function withUpdateParam(param: URLParams, callback: Function) {
    return (value: string) => {
      updateParam(param, value);
      callback(value);
    };
  }

  const contextValue: SchedulerContextType = {
    selectedDispatchSchedule,
    dispatchSchedulesOptions,
    isLoadingDispatchScheduleOptions,
    setSelectedDispatchSchedule,
    schedulerStartDate,
    schedulerEndDate,
    setSchedulerRangeDates,
    schedulerCurrentDate,
    setSchedulerCurrentDate,
    schedulerSortField,
    schedulerSortOrder,
    setSchedulerSortField: withUpdateParam('sortBy', setSchedulerSortField),
    setSchedulerSortOrder: withUpdateParam('order', setSchedulerSortOrder),
    schedulerFilterOptions,
    schedulerFilterValues,
    activeFilters,
    activeFiltersCount,
    initializeSchedulerFilterOptions,
    updateSchedulerFilterValue,
    clearActiveFilters,
    showOperators,
    onToggleShowOperators,
    brokerCompanies,
    isLoadingBrokerCompanies,
  };
  return (
    <SchedulerContext.Provider value={contextValue}>
      {children}
    </SchedulerContext.Provider>
  );
};
