// Helper functions for Dispatch Scheduler dashboard
import {
  compact,
  every,
  map,
  orderBy,
  pickBy,
  property,
  reduce,
  union,
  xor,
} from 'lodash';

import {
  BrokerCompaniesRecord,
  FilterField,
  FilterOptionsRecord,
  filterFields,
  schedulerFilterColumns,
} from '@/lib/context/SchedulerContext';

import { getUserCompanyId } from '@/lib/firebase/db/helpers';
import { JobDoc, UserDoc } from '@/lib/firebase/db/metaTypes';

import { FEATURES, getIsFeatureEnabled } from '@/lib/helpers/features';
import {
  isAssigner,
  isPosterAssigner,
  isSupport,
} from '@/lib/helpers/userRoles';

/**
 * Retrieves the value of a specified field from a job document, based on the scheduler filter configuration.
 * If a custom accessor function is defined for the field in the `schedulerFilterColumns` configuration, it is used.
 * Otherwise, a default accessor function is used that retrieves the value of the property with the same name as the field ID.
 * The value is always returned as a string.
 *
 * @param {JobDoc} job - The job document from which to retrieve the field value.
 * @param {FilterField} field - The field whose value is to be retrieved.
 * @param {Object} [options] - Optional parameters to customize the function's behavior.
 * @param {BrokerCompaniesRecord} [options.brokerCompanies] - An optional map of broker company documents, which can be
 *        used to resolve filter values that depend on related document lookups, such as filtering by broker company name.
 *
 * @returns {string} The value of the specified field from the job document, as a string.
 */
export function getJobFieldValue(
  job: JobDoc,
  field: FilterField,
  { brokerCompanies }: { brokerCompanies?: BrokerCompaniesRecord } = {}
) {
  function defaultAccessor(job: JobDoc) {
    return String(property(field)(job.data()));
  }
  const fieldValueAccessor =
    schedulerFilterColumns[field].accessor || defaultAccessor;
  return fieldValueAccessor(job, { brokerCompanies });
}

/**
 * Function to extract and aggregate filter options from an array of job documents.
 * @param {JobDoc[]} jobs - An array of job documents from which to extract filter options.
 * @param {Object} [options] - Optional parameters to customize the function's behavior.
 * @param {BrokerCompaniesRecord} [options.brokerCompanies] - An optional map of broker company documents, which can be
 *        used to resolve filter values that depend on related document lookups, such as filtering by broker company name.
 *
 * @returns {FilterOptionsRecord} An object containing arrays of unique filter options for each filter field.
 */
export function getFilterOptionsFromJobs(
  jobs: JobDoc[],
  { brokerCompanies }: { brokerCompanies?: BrokerCompaniesRecord } = {}
) {
  const filterOptions: FilterOptionsRecord = {};
  filterFields.forEach((filterField) => {
    let filterValues: string[] = [];
    const defaultOptions = schedulerFilterColumns[filterField].options;
    if (!!defaultOptions) {
      filterValues = defaultOptions;
    } else {
      filterValues = jobs
        .map((job) => getJobFieldValue(job, filterField, { brokerCompanies }))
        .flat();
      filterValues = orderBy(union(compact(filterValues)));
    }
    filterOptions[filterField] = filterValues;
  });
  return filterOptions;
}

/**
 * Updates the current filter values by either adding or removing the specified filter value.
 * This is achieved by performing an exclusive or (XOR) operation, which ensures that if the
 * filter value already exists in the array, it is removed; if it does not exist, it is added.
 *
 * @param {string[]} currentFilterValues - The current array of filter values.
 * @param {string} filterValue - The filter value to add or remove.
 * @returns {string[]} The updated array of filter values after the XOR operation.
 */
export function getUpdatedFilterValues(
  currentFilterValues: string[],
  filterValue: string
) {
  // XOR will add filterValue to currentFilterValues or remove it if it already exists.
  const newFilterValues = xor(currentFilterValues, [filterValue]);
  return newFilterValues;
}

/**
 * Determines whether a job document matches all active filters.
 * This function checks every field specified in the active filters against the corresponding value in the job document.
 * A job is considered filtered (i.e., it does not match) if for any field, it does not have a value that matches any of the active filter values for that field.
 * If there are no active filters for a field, or if the job's value for a field matches any of the active filter values for that field, the job is considered a match.
 *
 * @param {JobDoc} job - The job document to be checked against the active filters.
 * @param {FilterOptionsRecord} activeFilters - An object representing the active filters. Each key is a filter field, and each value is an array of strings representing the active filter values for that field.
 * @param {Object} [options] - Optional parameters to customize the function's behavior.
 * @param {BrokerCompaniesRecord} [options.brokerCompanies] - An optional map of broker company documents, which can be
 *        used to resolve filter values that depend on related document lookups, such as filtering by broker company name.
 *
 * @returns {boolean} True if the job matches all active filters, false otherwise.
 */
export function getIsJobFiltered(
  job: JobDoc,
  activeFilters: FilterOptionsRecord,
  { brokerCompanies }: { brokerCompanies?: BrokerCompaniesRecord } = {}
) {
  return every(activeFilters, (fieldValues = [], _field) => {
    const field = _field as FilterField;
    const isFilteringByThisField = !!fieldValues.length;
    const jobFieldValueOrValues = getJobFieldValue(job, field, {
      brokerCompanies,
    });
    const doesJobMatchesFilter =
      typeof jobFieldValueOrValues === 'string'
        ? fieldValues.includes(jobFieldValueOrValues)
        : jobFieldValueOrValues.some((value) => fieldValues.includes(value));
    return !isFilteringByThisField || doesJobMatchesFilter;
  });
}

export const FILTER_PARAMS_SEPARATOR = '&&&';
export const PARAM_VALUES_SEPARATOR = '=';
export const FILTER_VALUES_SEPARATOR = '\\\\';

/**
 * Converts scheduler filter values from an object to a base64 encoded string.
 * This function is useful for encoding filter parameters into a compact string format, making it easy to pass through URLs or store compactly.
 * It filters out any empty values to ensure only meaningful filter data is encoded.
 *
 * @param {FilterOptionsRecord} schedulerFilterValues - An object representing the filter options, where each key corresponds to a filter field and its value is an array of selected options.
 * @returns {string} A base64 encoded string representing the non-empty filter values.
 */
export function filterValuesToString(
  schedulerFilterValues: FilterOptionsRecord
): string {
  const nonEmptyFilters = pickBy(
    schedulerFilterValues,
    (values) => !!values?.length
  );
  const filtersString = map(nonEmptyFilters, (values, field) => {
    const valuesStr = values?.join(FILTER_VALUES_SEPARATOR);
    return `${field}${PARAM_VALUES_SEPARATOR}${valuesStr}`;
  }).join(FILTER_PARAMS_SEPARATOR);
  return btoa(filtersString);
}

/**
 * Converts a base64 encoded string back into an object representing scheduler filter values.
 * This function is the opposite of filterValuesToString.
 * It decodes the string and reconstructs the original object structure of filter parameters.
 *
 * @param {string} filtersStringEncoded - A base64 encoded string containing the filter parameters.
 * @returns {FilterOptionsRecord} An object representing the decoded filter options, where each key corresponds to a filter field and its value is an array of selected options.
 */
export function stringToFilterValues(
  filtersStringEncoded: string
): FilterOptionsRecord {
  const decodedFilterString = atob(filtersStringEncoded);
  const schedulerFilterValues = reduce(
    compact(decodedFilterString.split(FILTER_PARAMS_SEPARATOR)),
    (filterValues, paramAndValues) => {
      const [param = '', values] = paramAndValues.split(PARAM_VALUES_SEPARATOR);
      return {
        ...filterValues,
        [param]: values?.split(FILTER_VALUES_SEPARATOR),
      };
    },
    {}
  );
  return schedulerFilterValues;
}

/**
 * Determines whether a user has the permission to edit dispatch schedules.
 *
 * @param {UserDoc} userDoc - The document of the user whose permissions are being checked.
 * @returns {boolean} True if the user is allowed to edit dispatch schedules, false otherwise.
 */
export function getCanUserEditDispatchSchedule(userDoc: UserDoc) {
  return (
    isAssigner(userDoc) &&
    !!getIsFeatureEnabled(FEATURES.DISPATCH_SCHEDULE_EDIT_MODE, userDoc)
  );
}

/**
 * Determines if a user has permission to edit all job assignments.
 * This function checks if the user has the necessary role or relationship to the job based on their company affiliation
 * and the job's associated companies (broker, biller, and client). A user can edit all job assignments if they are marked
 * as support personnel or if they are a poster/assigner whose company matches either the job's broker company, biller company,
 * or client company. This ensures that only authorized users can make edits to job assignments, protecting against unauthorized changes.
 *
 * @param {UserDoc} userDoc - The document representing the user, containing user roles and company affiliation.
 * @param {JobDoc} jobDoc - The document representing the job, containing references to associated companies.
 * @returns {boolean} Returns `true` if the user has the authority to edit all job assignments, otherwise `false`.
 */
export function getCanUserEditAllJobAssignments(
  userDoc: UserDoc,
  jobDoc: JobDoc
): boolean {
  const userCompanyId = getUserCompanyId(userDoc);
  // Same logic as in function canUserEditJob from react-app repo
  return (
    isSupport(userDoc) ||
    (isPosterAssigner(userDoc) &&
      (userCompanyId === jobDoc.get('brokerCompanyRef')?.id ||
        userCompanyId === jobDoc.get('billerCompanyRef')?.id ||
        userCompanyId === jobDoc.get('client')?.id))
  );
}
